// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---gatsby-theme-doc-src-templates-index-js": () => import("/Users/apple/Desktop/Lab/labPluginTest/gatsby-theme-doc/gatsby-theme-doc/src/templates/index.js" /* webpackChunkName: "component---gatsby-theme-doc-src-templates-index-js" */),
  "component---gatsby-theme-doc-src-templates-page-js": () => import("/Users/apple/Desktop/Lab/labPluginTest/gatsby-theme-doc/gatsby-theme-doc/src/templates/page.js" /* webpackChunkName: "component---gatsby-theme-doc-src-templates-page-js" */),
  "component---src-pages-index-js": () => import("/Users/apple/Desktop/Lab/labPluginTest/gatsby-theme-doc/demo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-laptop-jsx": () => import("/Users/apple/Desktop/Lab/labPluginTest/gatsby-theme-doc/demo/src/pages/laptop.jsx" /* webpackChunkName: "component---src-pages-laptop-jsx" */)
}

